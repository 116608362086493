export const QUADRATIC_FOR_EDUCATION = 'https://docs.quadratichq.com/quadratic-for-education';
export const DOCUMENTATION_URL = 'https://docs.quadratichq.com';
export const DOCUMENTATION_PYTHON_URL = `${DOCUMENTATION_URL}/python`;
export const DOCUMENTATION_FORMULAS_URL = `${DOCUMENTATION_URL}/formulas`;
export const DOCUMENTATION_JAVASCRIPT_URL = `${DOCUMENTATION_URL}/javascript`;
export const DOCUMENTATION_JAVASCRIPT_RETURN_DATA = `${DOCUMENTATION_URL}/javascript/return-data-to-the-sheet`;
export const DOCUMENTATION_FILES_URL = `${DOCUMENTATION_URL}/spreadsheet/files`;
export const DOCUMENTATION_OFFLINE = `${DOCUMENTATION_URL}/spreadsheet/files#local-saving`;
export const DOCUMENTATION_CONNECTIONS_URL = `${DOCUMENTATION_URL}/connections`;
export const DOCUMENTATION_CONNECTIONS_IP_LIST_URL = `${DOCUMENTATION_CONNECTIONS_URL}/security#ip-allow-list`;
export const DOCUMENTATION_BROWSER_COMPATIBILITY_URL = `${DOCUMENTATION_URL}/spreadsheet/browser-compatibility`;
export const TRUST_CENTER = 'https://trust.quadratichq.com';
export const BUG_REPORT_URL = 'https://github.com/quadratichq/quadratic/issues';
export const DISCORD = 'https://discord.gg/quadratic';
export const TWITTER = 'https://twitter.com/quadratichq';
export const CONTACT_URL = 'https://quadratichq.com/contact';
export const WEBSITE_CONNECTIONS = 'https://www.quadratichq.com/connections';
export const WEBSITE_EXAMPLES = 'https://www.quadratichq.com/examples';
export const WEBSITE_CHANGELOG = 'https://www.quadratichq.com/changelog';
